import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @campaign.isArchived}}\n  <div class=\"campaign-archived-banner\">\n    <div class=\"campaign-archived-banner__text\">\n      <FaIcon class=\"campaign-archived-banner__icon\" @icon=\"archive\" />\n      <span>{{t \"pages.campaign.archived\"}}</span>\n    </div>\n    <PixButton @triggerAction={{this.unarchiveCampaign}} @backgroundColor=\"transparent-dark\" @isBorderVisible={{true}}>\n      {{t \"pages.campaign.actions.unarchive\"}}\n    </PixButton>\n  </div>\n{{/if}}", {"contents":"{{#if @campaign.isArchived}}\n  <div class=\"campaign-archived-banner\">\n    <div class=\"campaign-archived-banner__text\">\n      <FaIcon class=\"campaign-archived-banner__icon\" @icon=\"archive\" />\n      <span>{{t \"pages.campaign.archived\"}}</span>\n    </div>\n    <PixButton @triggerAction={{this.unarchiveCampaign}} @backgroundColor=\"transparent-dark\" @isBorderVisible={{true}}>\n      {{t \"pages.campaign.actions.unarchive\"}}\n    </PixButton>\n  </div>\n{{/if}}","moduleName":"pix-orga/components/campaign/header/archived-banner.hbs","parseOptions":{"srcName":"pix-orga/components/campaign/header/archived-banner.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class CampaignArchivedBanner extends Component {
  @service store;
  @service notifications;
  @service intl;

  @action
  async unarchiveCampaign() {
    try {
      const campaign = this.store.peekRecord('campaign', this.args.campaign.id);
      await campaign.unarchive();
    } catch (err) {
      this.notifications.error(this.intl.t('api-errors-messages.global'));
    }
  }
}
