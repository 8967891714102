import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.canShowCredit}}\n  <div class=\"organization-credit-info\">\n    <span class=\"organization-credit-info__label\">{{t\n        \"navigation.credits.number\"\n        count=this.currentUser.organization.credit\n      }}</span>\n\n    <PixTooltip\n      @id=\"credit-info-tooltip\"\n      @text={{t \"navigation.credits.tooltip-text\" htmlSafe=true}}\n      @position=\"bottom-left\"\n      @isWide={{true}}\n      @isLight={{true}}\n    >\n      <FaIcon @icon=\"info-circle\" class=\"info-icon\" tabindex=\"0\" aria-describedby=\"credit-info-tooltip\" />\n    </PixTooltip>\n  </div>\n{{/if}}", {"contents":"{{#if this.canShowCredit}}\n  <div class=\"organization-credit-info\">\n    <span class=\"organization-credit-info__label\">{{t\n        \"navigation.credits.number\"\n        count=this.currentUser.organization.credit\n      }}</span>\n\n    <PixTooltip\n      @id=\"credit-info-tooltip\"\n      @text={{t \"navigation.credits.tooltip-text\" htmlSafe=true}}\n      @position=\"bottom-left\"\n      @isWide={{true}}\n      @isLight={{true}}\n    >\n      <FaIcon @icon=\"info-circle\" class=\"info-icon\" tabindex=\"0\" aria-describedby=\"credit-info-tooltip\" />\n    </PixTooltip>\n  </div>\n{{/if}}","moduleName":"pix-orga/components/layout/organization-credit-info.hbs","parseOptions":{"srcName":"pix-orga/components/layout/organization-credit-info.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class OrganizationCreditInfoComponent extends Component {
  @service currentUser;

  get canShowCredit() {
    return this.currentUser.isAdminInOrganization && this.currentUser.organization.credit > 0;
  }
}
